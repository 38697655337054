import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Route, Link, Outlet, Routes } from 'react-router-dom';
import './App.css';
import { Box, Button, Paper } from '@mui/material';
import Explore from './pages/control';
import { UserManager } from 'oidc-client-ts';
import { connect } from 'react-redux';
import { app_login, app_logout } from './redux/actions/userActions';
import Control from './pages/control';
import { withNavigation } from './utility/withNavigation';

class App extends React.Component {
  render() {
    const _this = this;
    const Navigator = () => (
      <div>
        <Navbar sticky="top" bg="dark" variant="dark" expand="lg">
          <Navbar.Brand as={Link} to="/"><img style={{ width: "50px", height: "50px" }} src="/content/images/logo.png" alt="logo"></img></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link as={Link} to="/control" style={{ paddingRight: "20px" }}>Control</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Outlet />
      </div>
    )

    return (
      <div>
        <Routes>
          <Route path="/" element={<Navigator />}>
            <Route index element={<Home />} />
            <Route path="/control" element={<Explore />} />
          </Route>
        </Routes>
      </div>
    );
  }
}


function Home() {
  return (
    <Box p={0} style={{ backgroundColor: "black", height: "90vh" }}>
      <Paper elevation={3} style={{ backgroundColor: "black" }}>

        <img style={{ width: "40%", marginLeft: "30%" }} src="/content/images/logo.png" alt="vaudiox animation" loading="lazy" />

      </Paper>
    </Box>

  )
}

const mapState = (state) => { return { user: state.user } };
const mapDispatch = { app_login, app_logout };
export default connect(mapState, mapDispatch)(withNavigation(App));