import React from 'react';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import { io } from 'socket.io-client';
import base64js from 'base64-js';

const _site = "https://robo-mk1.vaudiox.com";
let socket = undefined;
let keysHeld = {}
let commandsOnlySendOnKeyUp = [
    "captureimg"
]
const keyCodeHandler = (keycode) => {
    switch (keycode) {
        case 87: return "forward";
        case 83: return "backward";
        case 65: return "left";
        case 68: return "right";
        case 32: return "captureimg";
    }
}
const keyHandler = (event, isKeyDown) => {
    let message = keyCodeHandler(event.keyCode);
    socket 
    && message 
    && (keysHeld[message] != isKeyDown)
    && !(commandsOnlySendOnKeyUp.indexOf(message) != -1 && isKeyDown)
    && socket.emit(message, isKeyDown);

    keysHeld[message] = isKeyDown;
    // socket && message && console.log(message + " " + isKeyDown);
}
const keyDownHandler = (event) => keyHandler(event, true);
const keyUpHandler = (event) => keyHandler(event, false);

class Control extends React.Component {
    state = {
        imageSrc: ""
    }
    componentDidMount() {
        const _this = this;
        socket = io(_site);
        socket.on("connect", () => {
            const engine = socket.io.engine;
            console.log(engine.transport.name); // in most cases, prints "polling"

            engine.once("upgrade", () => {
                // called when the transport is upgraded (i.e. from HTTP long-polling to WebSocket)
                console.log(engine.transport.name); // in most cases, prints "websocket"
            });

            engine.on("packet", ({ type, data }) => {
                // called for each packet received
                console.log("packet")
                console.log(type)
                if (type == "message" && data.length){
                    if (data[0] =="image") {
                        let base64 = base64js.fromByteArray(data[1]);
                        console.log(base64)
                        _this.setState({
                            imageSrc: `data:image/jpg;base64, ${base64}`
                        })
                    }
                }
                // console.log(data)

            });

            engine.on("packetCreate", ({ type, data }) => {
                // called for each packet sent
                console.log("packetCreate")

            });

            engine.on("drain", () => {
                // called when the write buffer is drained
                console.log("drain")
            });

            engine.on("close", (reason) => {
                console.log("close")
                console.log(reason)
            });
        });

        document.addEventListener('keydown', keyDownHandler);
        document.addEventListener('keyup', keyUpHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', keyDownHandler);
        document.removeEventListener('keyup', keyUpHandler);
    }
    render() {
        return (
            <Box p={0}>
                <img id="camera" src={this.state.imageSrc} style={{width:"100%", height:"100%"}}></img>
            </Box>
        );
    }
}

const mapState = (state) => { return { user: state.user } };
const mapDispatch = {};

export default connect(mapState, mapDispatch)(Control);